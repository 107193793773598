body {
  background-color: #101820ff;
  margin: 0px;
  font-family: sans-serif;
}

.top {
  background-color: #a2a2a1ff;
  margin: 0px;
}

.main-app {
  margin-left: 5%;
  margin-right: 5%;
}

.displayVideos {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.title {
  color: #006b38ff;
  text-align: center;
  font-weight: bold;
  font-family: sans-serif;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 0px;
}

button {
  margin: 10px;
  border-radius: 10px;
  color: #101820ff;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
  font-size: medium;
  background-color: #a2a2a1ff;
}

h4 {
  color: #006b38ff;
}

.button-heading {
  text-align: center;
  color: #a2a2a1ff;
  font-weight: bold;
}

.chosenTheme {
  text-align: center;
  color: #006b38ff;
}

iframe {
  flex: 1;
  width: 100%;
}

.video-item {
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  transition-property: box-shadow;
}

.card-div:hover {
  border-color: #006b38ff;
}
.card-div {
  border-color: #bbbbbb;
  border-radius: 10px;
  border-style: solid;
  margin-bottom: 15px;
  margin-left: 5px;
  margin-right: 5px;
}

img {
  width: 250px;
}

.book-buttons {
  margin-bottom: 20px;
}

.buttonSections {
  margin-left: 20px;
  margin-right: 20px;
}

.menu {
  display: flex;
  justify-content: space-evenly;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: #006b38ff;
  overflow: hidden;
}

@media all and (max-width: 800px) {
  .menu {
    flex-wrap: wrap;
  }
  .menu > li {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 50%;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
  }
  li a {
    padding: 5px 16px;
  }
}

li a {
  display: block;
  color: #101820ff;
  text-align: center;
  padding: 20px 16px;
  text-decoration: none;
  font-size: large;
  font-weight: bold;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: #a2a2a1ff;
}

.active {
  color: #a2a2a1ff;
}

a.active:hover {
  background-color: #006b38ff;
}
